import React from "react"
import styles from "./video.module.styl"

class Video extends React.Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
  }

  componentDidMount() {
    const isWeixinBrowser = /micromessenger/i.test(navigator.userAgent)

    if (isWeixinBrowser) {
      // console.log("微信端")
      this.video.current.setAttribute("x5-video-player-type", "h5")
    } else if (this.props.type !== "local") {
      this.video.current.setAttribute("poster", "")
    }
  }

  render() {
    const props = this.props

    return (
      <div className={styles.video + " " + styles[props.vclass]}>
        <video
          muted={props.type !== "local"}
          controls={props.type == "local"}
          autoPlay
          loop
          // x5-video-player-type="h5"
          webkit-playsinline="true"
          playsInline
          style={{ width: props.width }}
          poster={props.poster}
          src={props.src}
          ref={this.video}
        ></video>
        <span className={styles.name}>{props.name}</span>
      </div>
    )
  }
}

export default Video
