import React, { Component } from "react"
import styles from "./slider.module.styl"
import Slider from "react-slick"
import { event } from "./event.js"
import MBox from "./mBox"

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  UNSAFE_componentWillMount() {
    if (this.props.data && this.props.data[0].node.reverse) {
      event.emit("logo:reverse", true)
    }
  }
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 6000,
      arrows: false,
      infinite: true,
      pauseOnHover: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: current => {
        if (!this.props.data[current]) return
        if (this.props.data[current].node.reverse) {
          event.emit("logo:reverse", true)
        } else {
          event.emit("logo:reverse", false)
        }
      },
    }

    return (
      <div className={styles.slider}>
        <Slider ref={c => (this.slider = c)} {...settings}>
          {this.props.data.map((obj, index) => (
            <div className={styles.slider_item} key={index}>
              <div className={styles.image_placeholder}>
                <MBox
                  img={{
                    src: obj.node.img,
                    alt: obj.node.content,
                    cdn: obj.node.imgCDN,
                    bgcolor: obj.node.bgcolor,
                    fit: obj.node.bgsize,
                  }}
                  video={{
                    src: obj.node.video,
                    poster: obj.node.poster,
                    bgcolor: obj.node.bgcolor,
                    fit: obj.node.bgsize,
                  }}
                />
              </div>

              <div className="slider_nav prev" onClick={this.previous}></div>
              <div className="slider_nav next" onClick={this.next}></div>
              <div className={styles.box_wrap}>
                <div className={obj.node.reverse ? styles.reverse : ""}>
                  <a className="title" href={obj.node.href}>
                    <p>{obj.node.content}</p>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}
