import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Slider from "../components/slider.js"
import Me from "../components/me.js"
import Img from "gatsby-image"
import SEO from "../components/seo"
import styles from "./index.module.styl"

const intro = `Specialize in Brand indentites, Illustration,
Posters, Digital communication advertise, 
Brand positioning, Signage, Books and moreee.`
const intro2 = `Active in design and advertising Industry，
Constantly seek the harmony between these two.
Explore the possibilities of diverse expression in advertising and design，
And the importance of cultural expression in business.
Provide design solutions and consulting services for cultural and clients.`
const introCN = `在设计及广告间，
寻求两者共融。
广告中寻求更具
视觉识别的传播，
设计中寻求更有
传播力的图形识别。
为文化及商业客户
提供定位、广告与
设计方案及咨询服务。`
const introEN = `Active in design and 
advertising Industry，
Constantly seek the har-
mony between these two.
Explore the possibilities of
diverse expression in 
advertising and design，
And the importance 
of cultural expression in 
business.Provide design 
solutions and consulting 
services for cultural 
and clients.
`

export default () => {
  const data = useStaticQuery(graphql`
    query index {
      thumbs: allHomePicsJson(filter: {}, sort: { fields: index }) {
        edges {
          node {
            index
            img {
              childImageSharp {
                fluid(quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const imgs = {}
  const currentYear = new Date().getFullYear()
  data.thumbs.edges.forEach(item => (imgs[item.node.index] = item.node.img))

  return (
    <Layout>
      <SEO />
      <main className={styles.main}>
        <div className={styles.leftPics}>
          <Img
            fluid={{
              ...imgs[0].childImageSharp.fluid,
            }}
            style={{ width: `100%`, height: `auto` }}
          />
          <Img
            fluid={{
              ...imgs[5].childImageSharp.fluid,
            }}
            style={{ width: `50%`, height: `auto` }}
          />
          <Img
            fluid={{
              ...imgs[10].childImageSharp.fluid,
            }}
            style={{ width: `75%`, height: `auto` }}
          />
        </div>
        <div className={styles.content + " fade-in-top"}>
          <div className={styles.intro}>{intro}</div>
          <div className={styles.name}>
            <div className={styles.cai}>蔡</div>
            <div className={styles.yidong}>
              <div>YidOngCai</div>
              <div>依东</div>
            </div>
          </div>
          <div className={styles.intro2}>{intro2}</div>
          <div className={styles.introCN}>{introCN}</div>
          <div className={styles.introEN}>{introEN}</div>
        </div>
        <div className={styles.rightPics}>
          <span>2009-{currentYear}-moreee</span>
          <Img
            fluid={{
              ...imgs[15].childImageSharp.fluid,
            }}
            style={{ width: `100%`, height: `auto` }}
          />
          <Img
            fluid={{
              ...imgs[20].childImageSharp.fluid,
            }}
            style={{ width: `50%`, height: `auto` }}
          />
          <Img
            fluid={{
              ...imgs[25].childImageSharp.fluid,
            }}
            style={{ width: `36%`, height: `auto` }}
          />
          <Img
            fluid={{
              ...imgs[30].childImageSharp.fluid,
            }}
            style={{ width: `43%`, height: `auto` }}
          />
          <Img
            fluid={{
              ...imgs[35].childImageSharp.fluid,
            }}
            style={{ width: `100%`, height: `auto` }}
          />
          <Img
            fluid={{
              ...imgs[40].childImageSharp.fluid,
            }}
            style={{ width: `50%`, height: `auto` }}
          />
        </div>
      </main>
    </Layout>
  )
}
