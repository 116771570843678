import React from "react"
import { useEffect, useRef } from "react"

/**
 *
 * @param {
 *  vid: String
 *  ratio: Number
 * } props
 */
function Cuplayer(props) {
  const vid = props.vid
  const observed = useRef(null)
  const videoCSS = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
  }
  const renderPlayer = vid => {
    // console.log(window.polyvObject(`#${vid}`))
    window.polyvObject(`#${vid}`).videoPlayer({
      width: props.zoom || "100%",
      height: props.zoom || "100%",
      vid: vid,
      forceH5: true,
    })
  }
  const insertScript = p => {
    const $script = document.getElementById(p.id)
    if (!$script) {
      const script = document.createElement("script")
      script.src = p.src
      script.id = p.id
      script.onload = p.onLoad
      script.onerror = p.onError

      if (document.body) {
        document.body.appendChild(script)
      }
    } else {
      $script.addEventListener("load", p.onLoad)
    }
  }

  useEffect(() => {
    // console.log(observed.current)
    insertScript({
      src: "//player.polyv.net/script/polyvplayer.min.js",
      id: "cuplayer",
      onLoad: () => {
        renderPlayer(observed.current.id)
      },
      onError: e => {
        console.log(e)
      },
    })
  })

  return (
    <div
      className="cu_wrap"
      style={{
        position: "relative",
        paddingBottom: `${Number(100 / props.ratio).toFixed(2)}%`,
        width: "100%",
      }}
    >
      <div id={vid} ref={observed} style={videoCSS}></div>
    </div>
  )
}

export default Cuplayer
