import React from "react"
import styles from "./mbox.module.styl"
import Img from "gatsby-image"
import Video from "../components/video"
import Cuplayer from "../components/cuplayer"

/**
  @props {
    img: {
      src: "../images/xxx.jpg",
      alt: "name",
      cdn: "http://xxx.jpg",
      bgcolor: #000,
      fit: default | cover | contain,
      onClick: func(img)
    },
    video: {
      src: "../xxx/xxx.mp4",
      vid: "xxxxx",
      ratio: 2,
      zoom: 70%,
      poster: "http://xxx.jpg",
      bgcolor: #000,
      fit: default | cover | contain,
      vclass: "min",
      type: "local"
    }
  }
*/

class MBox extends React.Component {
  _boxClick() {
    if (this.props.img.src || this.props.img.cdn) {
      this.props.img.onClick()
    }
  }

  render() {
    let { img = {}, video = {} } = this.props
    const fit = img.fit || video.fit || "default"
    const bgcolor = img.bgcolor || video.bgcolor || "#000"

    const filter = () => {
      let jsx = null
      if (img.cdn) {
        jsx = <img src={img.cdn} alt={img.alt} />
      } else if (img.src && !img.src.childImageSharp) {
        jsx = <img src={img.src.publicURL} alt={img.alt} />
      } else if (img.src) {
        jsx = (
          <Img
            fluid={{
              ...img.src.childImageSharp.fluid,
            }}
            alt={img.alt}
          />
        )
      } else if (video.src) {
        jsx = (
          <Video
            src={video.src.publicURL}
            vclass={video.vclass}
            poster={video.poster}
            type={video.type}
          />
        )
      } else if (video.vid) {
        jsx = (
          <Cuplayer vid={video.vid[0]} ratio={video.ratio} zoom={video.zoom} />
        )
      }
      return jsx
    }

    return (
      <div
        className={styles.mbox + " " + styles[fit]}
        style={{ backgroundColor: bgcolor }}
        onClick={this._boxClick.bind(this)}
      >
        {filter()}
      </div>
    )
  }
}

export default MBox
